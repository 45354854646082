<template>
  <div class="booster-grid-container">
    <div class="d-flex align-items-center grid-item-1">
      <i
        class="fa fa-rocket fa-2x white-text mr-3"
        style="margin-top: -60px;"
      />
      <div
        class="primary"
        style="font-size: clamp(0.8rem, 1.4vw, 1.1rem)"
      >
        BOOSTING ALLOWS CLUBS TO INCREASE THE COST OF AN ENTRY. THIS ADDITIONAL BOOSTED AMOUNT IS THEN SHARED BY THE CLUB AND THE LEADERBOARD MONTHLY PRIZE!
        <div
          class="d-flex my-3"
          style="color: var(--pr-color);margin-top:25px !important;"
          @click="toggleBoost()"
        >
          <mdb-input
            v-model="boostEnabled"
            type="checkbox"
            auto-complete="ex-agr"
            size="sm"
            class="m-0 p-0"
          />
          <div class="text-white" style="font-size: 0.9rem;">
            {{ boostEnabled ? 'You have boost enabled' : 'Click here to enable boost' }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid-item-2 mtopmobile"
      style="margin-top: -100px;margin-left:15px;"
    >
      <!-- FORM OPTIONS -->
      <div
        v-if="boostEnabled"
        class="mb-4"
      >
        <div
          class="d-flex align-items-center"
        >
          <i class="fas fa-rocket" style="left:0;color:gray;" />
          <mdb-input
            v-model="boostAmount"
            type="number"
            auto-complete="nc-cnm"
            label="Boost Amount Per Entry"
            style="margin-left:20px;width: 240px"
            size="sm"
          />
        </div>
        <div class="d-flex align-items-center">
          <i class="fas fa-user" style="left:0;color:gray;" />
          <mdb-select
            v-model="boostShare"
            label="Club Leaderboard Share"
            style="margin-left:20px;width: 240px;"
          />
        </div>
        <mdb-btn
          class="btn primary-btn btn-radius m-0"
          style="width:100px;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"

          @click="saveBoost()"
        >
          <span v-if="!saving">SAVE</span>
          <span v-else><mdb-icon icon="fa fa-pulse fa-spinner" /></span>
        </mdb-btn>
      </div>
    </div>

    <Calculator class="grid-item-3 ml-xl-auto mauto" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'
import Calculator from './boosterCalculator.vue'

export default {
  name: 'Booster',
  components: { Calculator },
  data () {
    return {
      boostEnabled: false,
      boostAmount: 0,
      inPlay: false,
      boostShare: [
        { value: 50, text: 'Club ( 50% ) / LeaderBoard (50% )', selected: true },
        { value: 60, text: 'Club ( 60% ) / LeaderBoard (40% )' },
        { value: 40, text: 'Club ( 40% ) / LeaderBoard (60% )' },
        { value: 75, text: 'Club ( 75% ) / LeaderBoard (25% )' },
        { value: 25, text: 'Club ( 25% ) / LeaderBoard (75% )' },
        { value: 70, text: 'Club ( 70% ) / LeaderBoard (30% )' },
        { value: 30, text: 'Club ( 30% ) / LeaderBoard (70% )' },
        { value: 80, text: 'Club ( 80% ) / LeaderBoard (20% )' },
        { value: 20, text: 'Club ( 20% ) / LeaderBoard (80% )' },
        { value: 90, text: 'Club ( 90% ) / LeaderBoard (10% )' },
        { value: 10, text: 'Club ( 10% ) / LeaderBoard (90% )' },
        { value: 0, text: 'LeaderBoard (100% )' },
      ],
      saving: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    boostPercentage () {
      try {
        return this.boostShare.filter(t => t.selected)[0].value
      } catch { return 0 }
    },
  },
  mounted () {
    this.$emit('loading', false)
    this.getBoost()
  },
  methods: {
    toggleBoost () {
      this.boostEnabled = !this.boostEnabled
    },
    async getBoost () {
      try {
        const res = await baseService.getBoost(this.userData.access_token)
        this.boostEnabled = res.data.Amount > 0
        this.boostAmount = res.data.Amount
        this.inPlay = res.data.InPlay
        this.boostShare = this.boostShare.map(b => ( { ...b, selected: b.value == res.data.Percentage } ))
      } catch {
        //?
      }
    },
    async saveBoost () {
      this.saving = true
      try {
        const res = await baseService.saveBoost(this.boostAmount, this.boostPercentage, this.userData.access_token)
        if (res.data) {
          this.getBoost()
        } else {
          this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })  
        }
        this.saving = false
      } catch (err) {
        this.saving = false
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
    }
  }
}
</script>

<style>
  .dropdown-content li > a, .dropdown-content li > span {
    display: block;
    padding: 0.5rem;
    color: black !important;
    font-size: 12px !important;
    font-family: Arial, Helvetica, sans-serif !important;
    text-transform: capitalize !important;
  }
  .select-dropdown ul {
    padding: 0;
    list-style-type: none;
    border: solid 1px white;
}
</style>

<style lang="css" scoped>
.booster-grid-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  width: 100%;
  gap: 10px;
}

@media (max-width: 1021px) {
  .mtopmobile {
    margin-top:-20px !important;
  }
  .mauto {
    margin: auto;
  }
}

@media (min-width: 1200px) { /* XL screen breakpoint */
  .booster-grid-container {
    padding: 15px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "grid-item-1 grid-item-3"
      "grid-item-2 grid-item-3";
  }
  .grid-item-1 {
    grid-area: grid-item-1;
  }

  .grid-item-2 {
    grid-area: grid-item-2;
  }

  .grid-item-3 {
    grid-area: grid-item-3;
  }
}
</style>
