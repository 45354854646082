<template>
  <mdb-popover
    ref="popover"
    trigger="click"
    :options="{ placement: 'left'}"
    style="cursor:pointer;;"
  >
    <div
      slot="body"
    >
      <p
        v-if="showToggleToAdmin"
        :title="`Current Role: ${player.Role}`"
        class="d-flex align-items-center m-0 mb-1"
        style="color:#555 !important;font-size:0.6rem;"
        @click="requestConfirmRoleChange('Admin')"
      >
        <i class="fa fa-cog mr-2" />TOGGLE TO ADMIN?
      </p>
      <p
        v-if="showToggleToUser"
        :title="`Current Role: ${player.Role}`"
        class="d-flex align-items-center m-0 mb-1"
        style="color:#555 !important;font-size:0.6rem;"
        @click="requestConfirmRoleChange('Player')"
      >
        <i class="fa fa-user mr-2" />TOGGLE TO PLAYER?
      </p>
      <p
        class="d-flex align-items-center m-0 mb-1"
        style="color:#555 !important;font-size:0.6rem;"
        @click="openMailClient()"
      >
        <i
          class="fa fa-envelope mr-2"
          style="cursor:pointer;"
          :title="player.EmailAddress"
        />
        EMAIL THIS PLAYER?
      </p>
    </div>
    <mdb-btn
      slot="reference"
      style="background-color: none !important; height: 30px !important;color:#ffffff !important"
      class="p-1 m-0 btn-blank d-flex align-items-center"
    >
      <i class="fa fa-ellipsis-v" />
    </mdb-btn>
  </mdb-popover>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'PlayersPopover',
  props: {
    player: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['userData']),
    showToggleToAdmin () {
      // restriction logic - we only show this option if the player is a User or Legacy_User
      if(this.player.Role == 'Player' || this.player.Role == 'Legacy_User') {
        return true
      }
      return false
    },
    showToggleToUser () {
      // restriction logic - we only show this option if the player is a User or Legacy_User
      if(this.player.Role == 'Admin') {
        return true
      }
      return false
    }
  },
  methods: {
    requestConfirmRoleChange (newRole) {
      this.$store.commit('GENERIC_CONFIRM_MODAL', {
        showing: true,
        confirmTitle: 'SWITCH ROLE',
        confirmMessage: `Are you sure you want to change the selected users role to ${newRole}?`,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.doToggle(newRole)
      })
    },
    async doToggle (newRole) {
      this.loading = true
      const JSON = {
        UserId: this.player.Id,
        NewRole: newRole
      }
      try {
        await adminService.updateUserRoles(this.userData.access_token, JSON)
        this.$emit('refresh')
      } catch {
        //
      } finally {
        this.$store.commit('CLOSE_GENERIC_CONFIRM_MODAL')
        this.loading = false
      }
    },
    openMailClient() {
      window.open(`mailto:${this.player.EmailAddress}`);
    }
  }
}
</script>
