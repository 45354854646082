<template>
  <div style="height: 100%;">
    <div
      v-if="payouts.length > 0"
      class="text-center primary-colour mx-auto pb-2 pb-md-3"
      style="position: sticky;top: 0; width: 100%; background: var(--bg-color); font-size: clamp(0.8rem, 2vw, 1rem)"
    >
      Payments made 3rd of each month unless it falls on a weekend in which it will be made on the first working day after.
    </div>

    <BTable
      v-if="payouts.length > 0"
      id="payouts-table"
      class="text-left text-center payoutsTable"
      head-variant="dark"
      :items="payouts"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      responsive
      :striped="true"
      hover
      small
      sort-icon-left
      auto-width
    >
      <template v-slot:cell(month)="data">
        <div class="adminTableRow text-nowrap" :class="{'primary' : data.item.Forecast}">
          {{ new Date(data.item.Month).toLocaleString('default', { year: 'numeric', month: 'short' }) }}
        </div>
      </template>
      <template v-slot:cell(playercount)="data">
        <div class="adminTableRow" :class="{'primary' : data.item.Forecast}">
          {{ data.item.PlayerCount }} <span v-if="windowWidth < 577">({{ data.item.HeroCount }})</span>
        </div>
      </template>
      <template v-if="windowWidth > 576" v-slot:cell(herocount)="data">
        <div class="adminTableRow" :class="{'primary' : data.item.Forecast}">
          {{ data.item.HeroCount }}
        </div>
      </template>
      <template v-if="windowWidth > 576" v-slot:cell(amount)="data">
        <div class="adminTableRow" :class="{'primary' : data.item.Forecast}">
          £{{ data.item.Amount.toFixed(2) }}
        </div>
      </template>
      <template v-if="windowWidth > 576" v-slot:cell(boostamount)="data">
        <div class="adminTableRow" :class="{'primary' : data.item.Forecast}">
          £{{ data.item.BoostAmount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(totalamount)="data">
        <div class="adminTableRow" :class="{'primary' : data.item.Forecast}">
          £{{ data.item.TotalAmount.toFixed(2) }}
        </div>
      </template>
      <template v-slot:cell(ispaid)="data">
        <div
          v-if="data.item.Forecast"
          class="adminTableRow"
          :class="{'primary' : data.item.Forecast}"
        >
          <!-- PAID -->
          <span class="payoutAdminTableStatus ongoing">
            FORECAST
          </span>
        </div>
        <div
          v-else
          class="adminTableRow"
        >
          <!-- PAID -->
          <span v-if="data.item.IsPaid" class="payoutAdminTableStatus paid">
            <i class="fa fa-check" />
            PAID
          </span>
          <!-- DUE -->
          <span
            v-else-if="paymentIsDue(data.item.ActualMonth)"
            v-show="data.item.Amount > 0"
            class="payoutAdminTableStatus due"
          >
            <i class="fa fa-pulse fa-spinner" />
            DUE
          </span>
          <!-- ONGOING - NOT PAID BUT DATE OF PAYMENT IS IN THE FUTURE -->
          <span
            v-else
            class="payoutAdminTableStatus ongoing"
          >
            ONGOING
          </span>
          <!-- DUE -->
        </div>
      </template>
    </BTable>
    <div v-if="payouts.length > 0">
      <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
        <mdb-btn
          v-show="!spinning"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
          @click="getMyClubPayoutsCsv()"
        >
          DOWNLOAD
        </mdb-btn>
        <mdb-btn
          v-show="spinning"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
      <div style="color:#fff !important;margin-top:30px;font-size: smaller;">
        Download does not include forecast but does include 12 months of data. To obtain more than 12 months of data, please contact your account manager.
      </div>
    </div>
    <div
      v-else
      class="text-center primary-colour mx-auto pb-2 pb-md-3"
      style="position: sticky;top: 0; width: 100%; background: var(--bg-color); font-size: clamp(0.8rem, 2vw, 1rem)"
    >
      There are no transaction records to show presently.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import {PAYMENT_IS_DUE} from '@/common/utils'

export default {
  name: 'Payouts',
  components: { },
  data () {
    return {
      currentPage: 1,
      payouts: [],
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      spinning: false
    }
  },
  
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'Month', label: 'Payment Month' },
          { key: 'PlayerCount', label: 'Players', sortable: true },
          { key: 'HeroCount', label: 'Hero Count' },
          { key: 'Amount', label: 'Amount' },
          { key: 'BoostAmount', label: 'Boost' },
          { key: 'TotalAmount', label: 'Total' },
          { key: 'IsPaid', label: 'Status' }
        ]
      } else {
        return [
          { key: 'Month', label: 'Month' },
          { key: 'PlayerCount', label: 'Players', sortable: true },
          { key: 'TotalAmount', label: '£' },
          { key: 'IsPaid', label: 'Status' }
        ]
      }
    },
    perPage () {
      return 12
    },
    rows () {
      return 12
    }
  },
  mounted () {
    this.getPaymentsTableData()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getPaymentsTableData () {
      this.$emit('loading', true)
      try {
        const res = await adminService.getPaymentsTableData(this.userData.access_token)
        this.payouts = res.data
      } catch (err) {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      }
      setTimeout(() => {
        this.$emit('loading', false)
      }, 500)
    },
    paymentIsDue(paymentDate) {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()
      const checkDate = new Date(paymentDate)
      const paymentMonth = checkDate.getMonth()
      const paymentYear = checkDate.getFullYear()
      if (paymentMonth === currentMonth && paymentYear === currentYear) {
        return false
      } else {
        return PAYMENT_IS_DUE(paymentDate)
      }
    },
    getMyClubPayoutsCsv () {
      this.spinning = true
      adminService.getMyClubPayoutsCsv(this.userData.access_token).then((res) => {
        this.spinning = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinning = false
      })
    }
  }
}
</script>

<style>
.payoutAdminTableStatus.paid {
  color: var(--pr-color);
}
.payoutAdminTableStatus.due {
  color: red;
}
.payoutsTable * {
  font-size: clamp(0.7rem, 2vw, 1.2rem) !important;
}
</style>
